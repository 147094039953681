.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
}

.App-container{
  border: lightgrey solid 1px;
  padding: 1rem;
  border-radius: 2rem;
}

.App-title{
  padding: 10px 0px 10px 0px;
}

.App-body{
  text-align: left;
  padding: 0 0 10px 0;
}

.App-link {
  color: #61dafb;
}

.build-v{
  font-size: small;
}
.context-text{
  font-size: medium;
}

button {
  outline: none;
  height: 40px;
  text-align: center;
  width: 200px;
  border-radius: 40px;
  background-color: #282c34;
  border: 2px solid orange;
  letter-spacing: 1px;
  transition: all 0.25s ease;
}


button:hover {
  transition: all 0.25s ease;
  background-color: orange; /* Green */
  color: black;
}

.onclic {
  width: 40px;
  border-color: #bbbbbb;
  border-width: 3px;
  font-size: 0;
  border-left-color: #1ECD97;
  animation: rotating 2s 0.25s linear infinite;
}

.validate {
  font-size:13px;
  color: white;
  background: #1ECD97;
}

/*.validate:after{*/
/*  font-family:'FontAwesome',serif;*/
/*  content:"\f00c";*/
/*}*/

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#_gadget_context_file{
  /*font-weight: bold;*/
}

button.button-disabled{
  display: none;
}

.small-icon, .small-icon-trash{
  max-height: 20px;
  color: #1ECD97;
  transition: all 0.25s ease;
}

.small-icon-trash{
  height: 14px;
}

.fill-white{
  filter: invert(91%) sepia(100%) saturate(1%) hue-rotate(93deg) brightness(107%) contrast(101%);
}


#_gadget_action_refresh{
  background-color: lightskyblue;
  color: black !important;
  border: none;
  border-radius: 3px;
  padding: 10px 10px 30px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.4s;
  font-size: 12px;
  height: 15px;
}

._connect_error{
  color: orangered;
}

._connect_success{
  color: #1ECD97;
}

a{
  color: #3bbad7 !important;
}

a:hover{
  color: orange !important;
}

.small-icon-external{
  fill: white;
  height: 12px;
  margin-left: 10px;
}

#_gadget_action_unpublish span {
  color: white;
}
